import styled from 'styled-components';
import { WindowLocation } from '@reach/router';

import { ModalContextProvider } from '@contexts/ModalContext';
import { BenefitsSection } from '@src/modules/EvCharger/BenefitsSection';
import { ChooseYourCoverSection } from '@src/modules/EvCharger/ChooseYourCoverSection';
import { DiscoverMoreSection } from '@src/modules/EvCharger/DiscoverMoreSection';
import { HeroSection } from '@src/modules/EvCharger/HeroSection';
import { HowClaimsWorkSection } from '@src/modules/EvCharger/HowClaimsWorkSection';
import { TrustSection } from '@src/modules/EvCharger/TrustSection';
import { BasicPage } from '@components/layout';
import useEvCharger from '@hooks/useEvCharger';

import 'swiper/css';

const Root = styled.div`
  background-color: ${({ theme }) => theme.core.color.brand.offwhite};
`;

interface Props {
  location?: WindowLocation;
}

const EvCharger: React.FC<Props> = ({ location }) => {
  const { quotes } = useEvCharger();

  const evChargerNoExessQuote = quotes.find((quote) => quote.excess === 0);

  return (
    <Root>
      <ModalContextProvider>
        <BasicPage
          pageName="ev-charger"
          isNested={true}
          showTopPageBanner
          quote={evChargerNoExessQuote}
          location={location}
        >
          <HeroSection />
          <BenefitsSection />
          <ChooseYourCoverSection />
          <HowClaimsWorkSection />
          <DiscoverMoreSection />
          <TrustSection />
        </BasicPage>
      </ModalContextProvider>
    </Root>
  );
};

export default EvCharger;
